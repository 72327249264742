import React from 'react';
import { 
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';

import MainRoute from 'routes/MainRoute';
import HomeRoute from 'routes/HomeRoute';
import DeepLinkRoute from './DeepLinkRoute';
import NoMatchRoute from 'routes/NoMatchRoute';

const NavigationRoutes = () => {
    const RoutesJSX = (
        <Route path="/:locale" element={<MainRoute />}>
            <Route path="/:locale" element={<HomeRoute />} />
            <Route path="/:locale/:path" element={<DeepLinkRoute />} />
            <Route path="*" element={<NoMatchRoute />} />
        </Route>
    );

    const routes = createRoutesFromElements(RoutesJSX);
    const router = createBrowserRouter(routes);

    return <RouterProvider router={router} />;
};

export default NavigationRoutes;
