import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import styles from './deeplink.scss';
import DeepLinkButtonComponent from 'components/DeepLinkButtonComponent';
let cx = classnames.bind(styles);

const DeepLinkRoute = () => {

    const params = useParams();
    const path = params.path;

    useEffect(() => {
        // Stolen from Branch.io

        const isSafariBrowser = (userAgent) => {
            return !!/^((?!chrome|android|crios|fxios).)*safari/i.test(userAgent);
        }

        const isGreaterThanVersion = (userAgent, v) => {
            var match = /iPhone OS ([^ ]*)/i.exec(userAgent);
            if (match && match[1]) {
                try {
                    var version = match[1].replace(/_/gi, '.');
                    version = version.split('.');
                    version = parseFloat(version[0] + '.' + version[1]);
                    if (version >= v) {
                        return true;
                    }
                } catch (e) {
                    return false;
                }
            }
            return false;
        }

        const isSafari12Dot3OrGreater = () => {
            const userAgent = navigator.userAgent;
            const isSafari = isSafariBrowser(userAgent);

            if (isSafari) {
                return isGreaterThanVersion(userAgent, 12.3);
            }

            return false;
        };

        // Initially set isBlurred to false. If a CTA view comes up, then window.onblur() is going
        // to be called and isBlurred will be set to true
        let isBlurred = false;
        let fallbackHappened = false;

        const fallback = () => {
            if (document.webkitHidden || document.hidden || document.msHidden) {
                // The browser window is hidden. This means that the uri scheme properly opened.
                // No need to fallback
                return;
            }

            // The deepview is still visible. If it is blurred, this means that the uri scheme is in the
            // process of opening, or that the CTA view is still up. Do not fallback yet.
            // If the fallback already happened, then don't fallback again. This way we dont publish
            // a bunch of duplicate clicks due to our setInterval function below.
            const location = window.top.location.href;
            if (isBlurred || fallbackHappened || location === "https://apps.apple.com/app/id6471047524") {
                return;
            }

            fallbackHappened = true;

            if (isSafari12Dot3OrGreater()) {
                if (window.confirm("Open in App Store?")) {
                    window.top.location = "https://apps.apple.com/app/id6471047524";
                }
            } else {
                window.top.location = "https://apps.apple.com/app/id6471047524";
            }
        }

        const onBlur = () => {
            isBlurred = true;
        };

        const onFocus = () => {
            isBlurred = false;
            // if we end up focusing on the deepview again, this means that we should fallback
            // because it means that the uri scheme did not open
            fallback();
        };

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);

        window.top.location = "sush-vibes://app/" + path;

        setTimeout(function() {
            fallback();
        }, 250);

        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    return (
        <>
            <div className={cx('deeplink', 'flex', 'column', 'justify-content-center', 'align-items-center')}>
                <img src="/logo-website.png" alt="logo" className={cx('logo-icon')} />
                <DeepLinkButtonComponent path={path} />
            </div>
        </>
    );
};

export default DeepLinkRoute;
