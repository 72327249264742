import React from 'react';
import classnames from 'classnames/bind';

import styles from './home.scss';
import AppStoreButtonComponent from 'components/AppStoreButtonComponent';
let cx = classnames.bind(styles);

const HomeRoute = () => {

    const translation = window.data.translation;

    return (
        <>
            <div className={cx('flex', 'column', 'justify-content-center', 'align-items-center')}>
                <img src="/sush_apps.png" alt="logo" className={cx('apps-icon')} />
            </div>
            <div className={cx('home', 'flex', 'column', 'justify-content-center', 'align-items-center')}>
                <div className={cx('logo', 'flex', 'row', 'justify-content-center', 'align-items-center')}>
                    <img src="/logo-website.png" alt="logo" className={cx('logo-icon')} />
                </div>
                <h2 className={cx('description')}>
                    {translation['description']}
                </h2>
                <AppStoreButtonComponent />
            </div>
            <div className={cx('links', 'flex', 'row', 'justify-content-center', 'align-items-center')}>
                <a className={cx('link')} href="https://sush.app" target="_blank" rel="noopener noreferrer">SUSH.app</a>
                <a className={cx('link')} href="mailto:love@sush.app?subject=Vibes" target="_blank" rel="noopener noreferrer">Contact</a>
            </div>
        </>
    );
};

export default HomeRoute;
