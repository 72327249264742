import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useNProgress } from '@tanem/react-nprogress'
import classnames from 'classnames/bind';

import styles from 'styles/global.scss';
let cx = classnames.bind(styles);

import BarComponent from 'components/BarComponent';

const Container = ({ animationDuration, children, isFinished }) => (
    <div
      style={{
        opacity: isFinished ? 0 : 1,
        pointerEvents: 'none',
        transition: `opacity ${animationDuration}ms ease-out`,
      }}
    >
      {children}
    </div>
);

const Progress = ({
    animationDuration,
    incrementDuration,
    isAnimating,
    minimum
  }) => {
    const { isFinished, progress } = useNProgress({
        animationDuration,
        incrementDuration,
        isAnimating,
        minimum
    })

    return (
        <Container animationDuration={animationDuration} isFinished={isFinished}>
            <BarComponent animationDuration={animationDuration} progress={progress} />
        </Container>
    )
};

const MainRoute = () => {

    const location = useLocation();
    const [isAnimating, setIsAnimating] = useState(true);

    useEffect(() => {
        setIsAnimating(true);

        setTimeout(() => {
            setIsAnimating(false);
        }, 300);
    }, [location])

    return (
        <main>
            <Progress 
                animationDuration={300}
                isAnimating={isAnimating}
                key={location.key}
            />
            <div className={cx('background')}></div>
            <Outlet />
        </main>
    );
};

export default MainRoute;
