import React from 'react';
import ReactDOM from "react-dom/client";
import * as amplitude from '@amplitude/analytics-browser';

import App from './App';

amplitude.init("2889768e21d023e75f94051906a931e5");

const app = ReactDOM.createRoot(document.getElementById("app"));

app.render(<App />);