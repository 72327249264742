import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import * as amplitude from '@amplitude/analytics-browser';

import styles from './button.scss';
let cx = classnames.bind(styles);

const AppStoreButtonComponent = () => {

    const translation = window.data.translation;

    useEffect(() => {
        var button = document.getElementById('button');
        button.addEventListener('touchstart', handleButtonTouchStart);
        button.addEventListener('touchend', handleButtonTouchEnd);
        button.addEventListener('click', handleButtonClick);
    }, []);

    const handleButtonTouchStart = e => {
        e.returnValue = false;

        var button = document.getElementById('button');
        button.style.pointerEvents = 'none';
    }

    const handleButtonTouchEnd = e => {
        e.returnValue = false;

        var button = document.getElementById('button');
        button.style.pointerEvents = 'auto';

        window.open('https://apps.apple.com/app/id6471047524', '_blank');

        amplitude.track('Website > Open AppStore');
    }

    // For analytics on desktop only
    const handleButtonClick = e => {
        let isTouchDevice = ('ontouchstart' in document.documentElement);

        if (!isTouchDevice) {
            amplitude.track('Website > Open AppStore');
        }
    }

    return (
        <Link id="button" to="https://apps.apple.com/app/id6471047524" target="_blank" className={cx('flex', 'align-items-center', 'justify-content-center', 'download-button')}>
            <svg className={cx('icon')} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5979 4.37426C18.4546 3.30268 19.036 1.86386 18.8826 0.393555C17.6285 0.455916 16.0981 1.22095 15.212 2.29337C14.4164 3.21173 13.7123 4.7108 13.8959 6.11947C15.3037 6.24159 16.7102 5.41578 17.5979 4.37426Z" fill="black"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M18.8667 6.39488C16.8222 6.27309 15.0839 7.55523 14.1075 7.55523C13.1306 7.55523 11.6355 6.45625 10.0184 6.48586C7.91368 6.51678 5.96071 7.70682 4.89264 9.59954C2.6958 13.3859 4.31289 19.0025 6.44921 22.0863C7.48665 23.6119 8.73694 25.2918 10.3844 25.2314C11.941 25.1703 12.5511 24.2235 14.4431 24.2235C16.3338 24.2235 16.8834 25.2314 18.5311 25.2009C20.2399 25.1703 21.3081 23.6745 22.3455 22.1473C23.5357 20.4082 24.0229 18.7289 24.0535 18.6367C24.0229 18.6062 20.7586 17.3539 20.7283 13.599C20.6975 10.4549 23.2912 8.95946 23.4132 8.86672C21.9486 6.7005 19.6601 6.45625 18.8667 6.39488Z" fill="black"/>
            </svg>

            <div className={cx('labels', 'flex', 'column', 'justify-content-center', 'align-items-center')}>
                <span>{translation['get_app']}</span>
            </div>
        </Link>
    );
};

export default AppStoreButtonComponent;
