import React from 'react';
import classnames from 'classnames/bind';

import styles from './nomatch.scss';
let cx = classnames.bind(styles);

const NoMatchRoute = () => {

    const translation = window.data.translation;

    return (
        <div className={cx('nomatch', 'flex', 'column')}>
            <h1>{translation['nomatch_title']}</h1>
            <p>{translation['nomatch_description']}</p>
        </div>
    );
};

export default NoMatchRoute;
