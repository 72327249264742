import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import * as amplitude from '@amplitude/analytics-browser';

import styles from './button.scss';
let cx = classnames.bind(styles);

const DeepLinkButtonComponent = ({ ...prop }) => {

    const path = prop.path;

    useEffect(() => {
        var button = document.getElementById('button');
        button.addEventListener('touchstart', handleButtonTouchStart);
        button.addEventListener('touchend', handleButtonTouchEnd);
        button.addEventListener('click', handleButtonClick);
    }, []);

    const handleButtonTouchStart = e => {
        e.returnValue = false;

        var button = document.getElementById('button');
        button.style.pointerEvents = 'none';
    }

    const handleButtonTouchEnd = e => {
        e.returnValue = false;

        var button = document.getElementById('button');
        button.style.pointerEvents = 'auto';

        window.open(path, '_blank');

        amplitude.track('Website > Open DeepLink', { path: path });
    }

    // For analytics on desktop only
    const handleButtonClick = e => {
        let isTouchDevice = ('ontouchstart' in document.documentElement);

        if (!isTouchDevice) {
            amplitude.track('Website > Open DeepLink', { path: path });
        }
    }

    return (
        <Link id="button" to={"sush-vibes://app/" + path} className={cx('flex', 'align-items-center', 'justify-content-center', 'deeplink-button')}>
            <span>Open in App</span>
        </Link>
    );
};

export default DeepLinkButtonComponent;
